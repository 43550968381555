import {getUserData} from "../modulos/modulesUtils";
import SuperStorage from "../modulos/superStorage/superStorage";
import {loadBitbanModules} from "../bitban/load-modules-css";

import gtm, {sendPush} from "../marketing/gtm";
import events from "../marketing/events";
import onloadPage from "../marketing/onloadPage";

import {stickableSkies} from "../marketing/publicidad_skies";
import {makeFloatingBottomSticky} from "../marketing/publicidad_bottom";
import {configureAds, loadIntersticial, reloadAds} from "../marketing/publicidad";

import {doStickies} from "../modulos/stickyKit/StickyObserver";
import popUpRenewal from "../modulos/popUpRenewal/popUpRenewal";
import boletines from "../modulos/boletines/boletines";
import seo from "../seo/seo";
import favoritos from "../modulos/favoritos/favoritos";
import videoLive from "../modulos/videoLive/videoLive";
import {breadCrumbSwiper} from "../modulos/swiper/swiper";
import {imagesLazyLoad} from "../modulos/media/images";
import {getBaseQuoteSlug, unmaskpath} from "../modulos/modulesUtils";
import {dispatchEvent} from "../utils/eventDispatch";
import {search} from "../modulos/siteSearcher/search";

import '../bitban/global/animations.scss';
import {sendVisitBeacon} from "../modulos/visitsAndPremiumStats/visitsAndPremiumStats";
import {loadCloudflarePixel} from "../marketing/cloudflare";
import {loadNewRelicPixel} from "../marketing/newrelic";
import {removeYoutubeFrameConsent} from "../modulos/youtube/youtubeConsent";
import {checkUserConsentForPropose, waitUserConsentForPropose} from "../marketing/consent";
import {sendContentGroups} from "../marketing/contentGroups";
// import {loadHtmlScript} from "../utils/loadHtmlScript";

export const loadCommon = async () => {
    //Función que crea los url para las cotizaciones
    window.getBaseQuoteSlug = getBaseQuoteSlug

    //Función para enmascar la URL
    window.unmaskpath = unmaskpath

    //Eventos varios
    events();

    //CSS módulos Bitban
    loadBitbanModules();

    imagesLazyLoad();

    //Enlaces guiados por JS
    seo();

    //Atajo para publicar en GTM
    window.gtagPush = sendPush;

    //Atajo para lanzar eventos
    window.eventDispatch = dispatchEvent

    //Aspectos que se dispararán al cargar el DOM
    onloadPage();

    //Tracking de visitas: noticias, instrumentos o intentos de acceso a sección
    sendVisitBeacon();

    //Tracking de grupos de contenido
    sendContentGroups();

    //Sticky module (Usado para elementos sticky, como publi y otros elementos)
    doStickies();

    //Popup que avisa del fin de suscripción
    popUpRenewal();

    //Módulo de boletines
    boletines();

    //Inicializa favoritos
    favoritos();

    //Swiper. Slider como el del BreadCrumb.
    breadCrumbSwiper();

    //Función que permite obtener los datos del usuario. DEPRECATED (config with WP)
    window.getDataUser = (cb) => {
        getUserData()
            .then(data => cb(data))
            .catch(_ => cb(null));
    };

    //Exportar el SuperStorage. DEPRECATED (config with WP)
    window.superStorage = SuperStorage;

    //Eventos GTM
    gtm();

    //Atajo para publicar en GTM
    window.gtagPush = sendPush;

    // Integración con Google Search Engine
    search();

    checkUserConsentForPropose()
        .then(async (_) => {
            loadIntersticial();

            try {
                await configureAds({
                    adsSelector: 'div.ad-holder>div.add',
                    stickyHandler: stickableSkies,
                    bottomHandler: makeFloatingBottomSticky,
                })
                    .then(_ => {
                        reloadAds()
                    });

            } catch (e) {
                console.error(e)
                console.error('Fail loading ads:');
            }
        });


    waitUserConsentForPropose('cookies', () => {
        // New Relic pixel
        loadNewRelicPixel();

        //Carga la previsualización de falso directo de Bermejo. Youtube requiere consentimiento
        videoLive();

        //Cloudflare script
        loadCloudflarePixel();

        //Eliminar el paso intermedio de Consent en vídeos de Youtube
        removeYoutubeFrameConsent();
    });
}
