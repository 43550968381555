const getElementPosition = (element, offset) => {
    if (!element) {
        throw new Error('Element is required for position calculation');
    }
    const rect = element.getBoundingClientRect();
    return rect.y + window.scrollY - (offset || 0);
};
const getParentAtLevel = (element, level) => {
    let parent = element;
    for (let i = 0; i < level; i++) {
        if (!parent.parentNode) {
            throw new Error(`No parent found at level ${level}`);
        }
        parent = parent.parentNode;
    }
    return parent;
}

const throttle = (callback, limit) => {
    let waiting = false;
    return (...args) => {
        if (!waiting) {
            const result = callback(...args);
            waiting = true;
            setTimeout(() => (waiting = false), limit);
            return result;
        }
    };
};


export default class StickyObserver {
    constructor(element, options = {}) {
        if (!element) {
            throw new Error('Element is required');
        }

        this.element = element;
        this.beacon = null;
        this.offset = parseInt(options.offset, 10) || 90;
        this.stickClasses = options.stickClasses || [];
        this.onStick = options.onStick || (() => {});
        this.onUnstick = options.onUnstick || (() => {});
        this.throttleTime = parseInt(options.throttleTime, 10) || 150;
        this.parentLevel = parseInt(options.parentLevel, 10) || 1;

        this.observers = [];
        this.scrollListener = null;
    }

    init() {
        this.beacon = document.createElement('div');
        this.beacon.classList.add('stickable-beacon');
        this.element.parentNode.insertBefore(this.beacon, this.element);
        this.observeElement();
    }

    observeElement() {
        let isSticky = false;
        let originalPosition = getElementPosition(this.beacon, this.offset);

        const updatePosition = () => {
            const newPosition = getElementPosition(this.beacon, this.offset);
            if (newPosition !== originalPosition) {
                // console.log(this.element.id,`Change from ${originalPosition} to ${newPosition}`);
                originalPosition = newPosition;
            }
        };

        if ('MutationObserver' in window) {
            const parentToObserve = getParentAtLevel(this.beacon, this.parentLevel);
            const observer = new MutationObserver(updatePosition);
            observer.observe(parentToObserve, { childList: true, subtree: true });
            this.observers.push(observer);
        } else {
            console.warn("StickyObserver: MutationObserver is not supported. Using setInterval as fallback.");
            setInterval(updatePosition, 1_000);
        }

        this.scrollListener = throttle(() => {
            const scrollTop = window.scrollY;
            // console.log(this.element.id, scrollTop, originalPosition);
            if (scrollTop < originalPosition && isSticky) {
                isSticky = false;
                this.element.classList.remove('stickable-sticky', ...this.stickClasses);
                this.onUnstick(this.element);
            } else if (scrollTop >= originalPosition && !isSticky) {
                isSticky = true;
                this.element.classList.add('stickable-sticky', ...this.stickClasses);
                this.onStick(this.element);
            }
        }, this.throttleTime);

        window.addEventListener('scroll', this.scrollListener, { passive: true });
    }

    destroy() {
        this.observers.forEach(observer => observer.disconnect());
        this.observers = [];
        if (this.scrollListener) {
            window.removeEventListener('scroll', this.scrollListener);
        }
        if (this.beacon) {
            this.beacon.remove();
        }
        console.log(`StickyObserver for ${this.element.id || this.element.tagName} destroyed.`);
    }
}

export const doStickies = () => {
    const mediaQuery = window.matchMedia('(min-width: 740px)');
    if (!mediaQuery.matches) {
        console.log('Viewport < 740px');
        return;
    }

    document.querySelectorAll('.stickable')
        .forEach(element => {
            const parentLevel = element.dataset.stickyParent;
            const offset = element.dataset.stickyOffset;
            new StickyObserver(element, {parentLevel, offset}).init();
        })

}